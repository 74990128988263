import React, { useState, useEffect, useRef } from 'react';

function Paragraph(props) {
  const pRef = useRef(null);
  const imgRef = useRef(null);
  const aRef = useRef(null);

  useEffect(() => {
    let offset = pRef.current.getBoundingClientRect().top;
    if (window.innerWidth < 768) {
      if (offset < 300) {
        showImage(props.item.img)
      }

      if (offset < 20) {
        hideImage()
      }

      if (offset > 300) {
        hideImage()
      }
    }

    window.addEventListener('scroll', () => {
      if (window.innerWidth < 768) {
        offset = pRef.current.getBoundingClientRect().top;

        if (offset < 300) {
          showImage(props.item.img)
        }

        if (offset < 20) {
          hideImage()
        }

        if (offset > 300) {
          hideImage()
        }
      }
    })
  })

  function showImage(link) {
    imgRef.current.classList.add('visible');
    aRef.current.classList.add('visible');
  }

  function hideImage() {
    imgRef.current.classList.remove('visible');
    aRef.current.classList.remove('visible');
  }

  return (
    <>
      <p className="mainP" ref={pRef}>
        <a ref={aRef} onMouseEnter={() => showImage(props.item.img)} onMouseLeave={() => hideImage()} href={props.item.link} target="_blank">{props.item.name}</a>
        {" — " + props.item.desc + "   "}
      </p>
      <div className="badges">
          {props.item.design ? (<span className="badge">design</span>) : ""}
          {props.item.code ? (<span className="badge">code</span>) : ""}
      </div>
      <img className="webImg" ref={imgRef} src={props.item.img}></img>
    </>
  );
}

export default Paragraph;
