import React, { useState, useEffect, useRef } from 'react';

function Images(props) {
  useEffect(() => {
  })

  return (
    <div className='imgSection'>
      <p className="small">*completely random*</p>
      <img src='images/pngs/nail-art.png'></img>
      <img src='images/pngs/dvadsatiy.png'></img>
      <img src='images/pngs/april_kinik.png'></img>
      <img src='images/pngs/april_kinik_2.JPG'></img>
      <img src='images/pngs/plant-swap.png'></img>
      <img src='images/pngs/DotCot.png'></img>
      <img src='images/pngs/new_sincerity.png'></img>
      <img style={{maxWidth: '44vw'}} src='images/pngs/portugal_zin_1.jpeg'></img>
      <img style={{maxWidth: '44vw'}} src='images/pngs/portugal_zin_2.jpeg'></img>
      <img src='images/pngs/remote_origin.png'></img>
      <img src='images/pngs/rigl_01.jpeg'></img>
      <img style={{maxWidth: '60vw'}} src='images/pngs/YC_logo.png'></img>
      <img src='images/pngs/yc_kin.jpg'></img>
      <img src='images/pngs/MU-methods.png'></img>
      <img src='images/pngs/traffic_1.jpeg'></img>
      <img src='images/pngs/traffic_2.jpeg'></img>
      <img src='images/pngs/sreda_obitaniya.jpg'></img>
      <img src='images/pngs/thoughts_poster.png'></img>
      <img style={{maxWidth: '44vw'}} src='images/pngs/ground_1.jpeg'></img>
      <img style={{maxWidth: '44vw'}} src='images/pngs/ground_2.jpeg'></img>
      <img src='images/pngs/riso_1.png'></img>
    </div>
  );
}

export default Images;
