import logo from './logo.svg';
import './App.css';
import data from './content';
import React, { useState, useEffect, useRef } from 'react';
import Paragraph from './Paragraph.jsx';
import Images from './Images.jsx';

function App() {
  const [imgUrl, setImgUrl] = useState('')
  const imageRef = useRef(null);
  const pRef = useRef(null);

  // useEffect(() => {
  //   pRef.current.style.transform = 'scale(0)';
  //   setTimeout(function () {
  //       pRef.current.style.transform = 'scale(1)';
  //   }, 1000);
  // })

  function showImage(link) {
    // imageRef.current.style.display = 'block';
    // imageRef.current.style.transform = 'scale(0)';
    // setTimeout(function () {
    //   imageRef.current.style.transform = 'scale(0.5)';
    // }, 10);
    setImgUrl(link);
    // imageRef.current.style.display = 'block';
    imageRef.current.classList.add('visible');
  }

  function hideImage() {
    // imageRef.current.style.transform = 'scale(0)';
    imageRef.current.classList.remove('visible');
  }

  return (
    <div className="App">
      <p id="header" className="heading" ref={pRef}>
        danila ting — <a className='visible' href="https://www.instagram.com/danila_ting/" target="_blank">inst</a> / <a className='visible' href="https://t.me/danila_ting" target="_blank">tg</a> <br/>
        ux/ui, web & graphic designer
      </p>

      <div id="web" className="topics"><p className="topic current">web ↓</p><p className="topic"><a href='#img'>img</a></p></div>

      { data.map((item, i) => {
        return (
          <Paragraph key={i} item={item} showImage={showImage} hideImage={hideImage}/>
        )
      }) }

      <div id="img" className="topics"><p className="topic"><a href='#web'>web</a></p><p className="topic current">img ↓</p></div>

      <Images/>
      <p><a className='backToTop' href="#header">back to top ↑</a></p>
      <p className='small' style={{fontSize: '16px', marginBottom: '0', lineHeight: '16px'}}>2023 © Danila Ting</p>
    </div>
  );
}

export default App;
// <img ref={imageRef} src={imgUrl} width="1440" height="822"></img>
